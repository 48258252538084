import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Success from "../views/Success.vue";
import Cancel from "../views/Cancel.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/merci",
        name: "Success",
        component: Success
    },
    {
        path: "/echec",
        name: "Cancel",
        component: Cancel
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
