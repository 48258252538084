<template>
  <form>
    <a href="https://meurope.org">
      <img
        :src="`${publicPath}meurope.png`"
        class="meuropelogo"
        alt="M'Europe"
      />
    </a>
    <h1 class="title">Don par carte bancaire</h1>
    <div class="form-group" @submit.prevent="onSubmit">
      <label for="amount" class="col col-form-label col-form-label-lg">
        Montant
      </label>
      <div class="col">
        <div class="input-group">
          <input
              class="form-control"
              id="amount"
              aria-describedby="nameHelp"
              placeholder=""
              v-model="amount"
              type="number"
              min="0"
              required
          />
          <div class="input-group-append">
            <div class="input-group-text">&euro;</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" @submit.prevent="onSubmit">
      <label for="recipient" class="col-form-label-lg">Destinataire</label>
      <!--      <option v-for="item in items" :key="item.id">-->
      <!--        {{ item }}-->
      <!--      </option>-->

      <select class="form-control custom-select" id="recipient" v-model="recipient" required>
        <optgroup v-for="(options, key) in categoriesGroups" v-bind:key="key" v-bind:label="key">
          <option v-for="item in options" v-bind:key="item" v-bind:value="item">{{ item }}</option>
        </optgroup>
      </select>
    </div>
    <div class="form-group" @submit.prevent="onSubmit" v-if="recipient=='Autre'">
      <label for="more" class="col-form-label-lg">Merci de préciser</label>
      <input class=" form-control" id="more" placeholder="But du don"
             v-model="more_info" @keyup.enter="onSubmit">
    </div>
    <button type="button" id="give-button" class="btn btn-lg"
            :class="{ 'btn-success': isActive, 'btn-secondary': !isActive }"
            @click="onSubmit">Donner <span v-if="isActive">{{ amount }} &euro; pour {{ purpose }}</span>
    </button>
    <div class="alert alert-success tax-deduction" role="alert" v-if="amount>0">
      <p>Soit <strong>{{ afterDeduction }}&nbsp;&euro;</strong> après réduction d'impôts.</p>
      <p>Cette réduction est de 66%
        du montant de votre don dans la limite de 20% de votre revenu imposable.</p>
    </div>
    <br/>
    <a class="btn btn-primary another" href="https://meurope.org/#give">Donner par virement ou chèque</a>

  </form>
</template>

<script>
import EventService from "@/services/EventService";
import {loadStripe} from '@stripe/stripe-js';


export default {
  name: "GiveInput",
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      amount: 0,
      recipient: "None",
      categoriesGroups: {
        "Soutien général": [
          "M'Europe",
          "YFC Romania",
          "Respérance",
        ],
        "Soutien missionnaire": [
          "JP Ely Longeron",
          "Adalberto et Miranda Hawkins",
          "David et Rebecca Clinkscales",
          "Elie et Evelyne Dufour",
          "Stefan et Emilia Grancea",
          "Sorin et Mihaela Mihalache",
        ],
        "Soutien projet": [
          "3000 steps",
          "Poarta Oilor",
          "Camps enfants Roumanie",
          "Parrainage enfants Roumanie",
          "Run4mission",
        ],
        "Autre": [
          "Achat du livre 'Priez donc'",
          "Semaines Missionnaires Roumanie",
          "Autre",
        ],
      },
      more_info: "",
      publicKey: "",
      stripe: Object
    }
  }
  ,
  mounted() {
    this.loadConfig();
  }
  ,
  computed: {
    isActive() {
      return this.amount > 0 && this.recipient !== 'None' && (this.recipient !== 'Autre' || this.more_info !== '');
    }
    ,
    afterDeduction() {
      return (this.amount * 0.34).toFixed(0);
    }
    ,
    purpose() {
      return this.recipient == 'Autre' ? this.recipient + " : " + this.more_info : this.recipient;
    }
  }
  ,
  methods: {
    loadConfig() {
      console.log(process.env.NODE_ENV);
      EventService.getConfig().then(response => {
        this.publicKey = response.publicKey;
        loadStripe(response.data.publicKey).then(response => {
          this.stripe = response;
        })

      })
          .catch(error => {
            console.error('there was an error ' + error.response)
          })

    }
    ,
    onSubmit() {

      const purpose = this.recipient == 'Autre' ? this.recipient + " : " + this.more_info : this.recipient;
      if (this.isActive) {
        EventService.createCheckoutSession(this.amount, purpose)
            .then(response => {
              console.info(response.data)
              this.stripe.redirectToCheckout({
                sessionId: response.data.sessionId
              }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.
                if (result.error) {
                  console.error(result.error.message);
                }
              })

            })
            .catch(error => {
              console.error('there was an error ' + error)
            })
      }

    }
    ,
  }
}
;
</script>

<style scoped>
.title {
  font-size: 32px;
  margin-bottom: 20px;
}

#give-button {
  margin-top: 40px;
}

.another {
  margin-top: 40px;
  margin-bottom: 20px;
}

.tax-deduction {
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>
