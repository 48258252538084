<template>
  <div class="row alert alert-warning">
    <div class="col-8 offset-md-2 ">
      <a href="https://meurope.org"><img :src="`${publicPath}meurope.png`" class="meuropelogo"
                                         alt="M'Europe"></a>
      <h1 class="">Le paiement n'a pas abouti.</h1>
      <a class="btn btn-success retry" href="/">Essayer à nouveau</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
<style scoped>


.retry {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
