<template>
  <div class="row">
    <div class="col-md-4 col-sm-12 offset-md-4">
      <GiveInput/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GiveInput from "@/components/GiveInput.vue";

export default {
  name: "Home",
  components: {
    GiveInput
  }
};
</script>
