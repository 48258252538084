<template>
  <div class="row  alert alert-success">
    <div class="thank-you col-md-8   col-sm-12 offset-md-2">
      <a href="https://meurope.org"><img :src="`${publicPath}meurope.png`" class="meuropelogo"
                                         alt="M'Europe"></a>
      <h1>Merci beaucoup pour votre don !</h1>
      <img :src="`${publicPath}thankyou.jpg`" alt="Merci" class="thank-you-picture">
      <h2>Dieu aime celui qui donne avec joie !</h2>
      <h3>2 Corinthiens 9 verset 7</h3>
      <a class="btn btn-success goback" href="https://meurope.org">Retour sur le site principal</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
<style scoped>

.goback {
  margin-top: 40px;
  margin-bottom: 40px;
}

.thank-you {
  margin-bottom: 200px;
}

.thank-you-picture {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
