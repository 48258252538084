import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL + '/payment',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getConfig() {
        return apiClient.get("/config");
    },
    createCheckoutSession(quantity, purpose) {
        return apiClient.post("/create-checkout-session", {
            quantity: quantity * 100,
            purpose: purpose,
            locale: "fr"
        })
    }
}
